@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: rgb(66, 66, 66);
  text-decoration: none;
  font-weight: normal;
}
* nav {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
* .visible-item {
  opacity: 100%;
  transition: all 250ms ease-in-out;
}
* .hidden-item {
  opacity: 0%;
  pointer-events: none;
  transition: all 250ms ease-in-out;
}

.btn {
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  align-self: center;
  gap: 0.5rem;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  min-height: 34px;
  justify-self: flex-start;
}
.btn:active {
  transform: translateY(2px);
}
.btn * {
  color: white;
}
.btn .icon {
  font-size: 1rem;
  height: 1rem;
}
.btn .mirrorY {
  transform: rotateY(180deg);
}
.btn.action-btn {
  background-color: rgba(30, 74, 140, 0.85);
}
.btn.action-btn:hover {
  background-color: rgb(30, 74, 140);
}
.btn.mini-btn {
  background-color: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 2rem;
  border-radius: 0;
}
.btn.mini-btn * {
  color: green;
}
.btn.mini-btn:last-child {
  margin-left: 0.5rem;
}
.btn.mini-btn:last-child * {
  color: red;
}
.btn.mini-btn:hover {
  background-color: rgb(214, 214, 214);
}
.btn.auth-btn {
  background-color: rgba(14, 190, 243, 0.85);
  background-color: rgba(12, 73, 107, 0.85);
}
.btn.auth-btn:hover {
  background-color: rgb(12, 73, 107);
}
.btn.card-head-btn {
  background-color: rgba(14, 190, 243, 0.85);
  background-color: rgba(12, 73, 107, 0.85);
  border-radius: 0;
}
.btn.card-head-btn:hover {
  background-color: rgb(12, 73, 107);
}
.btn.framed-btn {
  background-color: white;
  color: unset;
  border: 2px solid rgba(66, 66, 66, 0.3);
  transition: all 350ms ease-in-out;
}
.btn.framed-btn .icon {
  height: 1.25rem;
  width: 1.25rem;
}
.btn.framed-btn .icon * {
  color: black;
}
.btn.framed-btn:hover {
  border-color: black;
}
.btn.bold-btn {
  background-color: white;
  color: unset;
  transition: all 350ms ease-in-out;
  padding: 0.5rem;
  transition: none;
}
.btn.bold-btn .icon {
  height: 1.25rem;
  width: 1.25rem;
}
.btn.bold-btn .icon * {
  color: black;
}
.btn.bold-btn:hover {
  background-color: rgb(238, 238, 238);
}
.btn.active-framed-btn {
  background-color: black;
  color: white;
  border: 2px solid black;
}
.btn.marketing-btn {
  align-self: flex-start;
  background-color: rgb(15, 185, 94);
  margin-top: 2rem;
}
.btn.marketing-btn:hover {
  background-color: rgba(15, 185, 94, 0.85);
}

.breadcrumbs {
  margin-bottom: 2rem;
  font-size: 0.8rem;
  opacity: 50%;
  padding: 1rem;
}

input[type=checkbox] {
  width: 1.5rem;
  height: 1.5rem;
}

input,
select,
textarea {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 30%;
  font-size: 1rem;
}
input:focus,
select:focus,
textarea:focus {
  border-color: rgb(0, 123, 255);
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

textarea {
  resize: vertical;
}

.icon-set {
  display: flex;
  gap: 1px;
  list-style: none;
}

.admin-code {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 0.75rem;
}
.admin-code span {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  width: 2.5rem;
  height: 2.5rem;
  font-weight: bold;
  font-size: 1.5rem;
  border-radius: 5px;
  text-transform: capitalize;
}
.admin-code .icon {
  cursor: pointer;
  font-size: 1.25rem;
}

.button-container {
  display: flex;
  justify-content: center;
  padding: 2rem;
  gap: 1rem;
}

.table-container h3 {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #333;
}
.table-container table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}
.table-container table thead tr {
  background-color: rgba(38, 86, 100, 0.75);
}
.table-container table thead tr th {
  padding: 0.75rem;
  text-align: left;
  color: white;
}
.table-container table thead tr th span {
  display: flex;
  align-items: center;
  color: white;
}
.table-container table thead tr th span * {
  color: white;
}
.table-container table thead tr th span .icon {
  cursor: pointer;
  margin-left: 0.25rem;
}
.table-container table thead tr th span input {
  margin-right: 1rem;
  width: 1rem;
  cursor: pointer;
}
.table-container table thead tr th:last-child {
  width: 6.5rem;
}
.table-container table tbody tr {
  cursor: pointer;
}
.table-container table tbody tr:hover {
  background-color: #f2f2f2;
}
.table-container table tbody tr td {
  padding: 0.75rem;
  border-bottom: 1px solid #ddd;
}
.table-container table tbody tr td:last-child {
  width: 6.5rem;
  text-align: right;
}
.table-container table tbody tr td span {
  display: flex;
  align-items: center;
}
.table-container table tbody tr td span input {
  margin-right: 1rem;
  width: 1rem;
  cursor: pointer;
}
.table-container .action-cell {
  text-align: unset;
}
.table-container p {
  text-align: center;
  color: #888;
  font-style: italic;
}

.App {
  min-height: 100vh;
  display: flex;
}

.page-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.page-layout header,
.page-layout footer {
  display: flex;
  flex-direction: column;
}
.page-layout header {
  border-bottom: 2px solid rgba(13, 51, 63, 0.1);
}
.page-layout footer {
  margin-top: auto;
  margin-bottom: 0;
  border-top: 2px solid rgba(13, 51, 63, 0.1);
}
.page-layout main {
  display: flex;
  height: 100%;
}
.page-layout main aside {
  background-color: #1f2937;
  border-radius: 0 10px 10px 0;
}
.page-layout main .content-container {
  width: 100%;
}
.page-layout main .content-container .content {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.page-layout main .content-container .content .card {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.page-layout main .content-container .content .card h2 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.page-layout main .content-container .content .card .card-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  gap: 2rem;
}
.page-layout main .content-container .content .card .card-header .table-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.page-layout main .content-container .content .card .card-header .table-filter .icon.search-icon {
  height: 30px;
  width: 30px;
  margin-left: -33px;
  background-color: white;
  cursor: text;
}
.page-layout main .content-container .content .card .card-header .table-filter .icon.import-icon {
  transform: rotateY(180deg);
}
.page-layout main .content-container .content .card form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.page-layout main .content-container .content .card form .form-group {
  display: flex;
  align-items: center;
  width: 100%;
}
.page-layout main .content-container .content .card form .form-group label {
  text-align: right;
  padding-right: 2rem;
  width: 35%;
}
.page-layout main .content-container .content .card form .form-group .name-span {
  text-transform: capitalize;
}
.page-layout main .content-container .content .card form .btn {
  margin-top: 1rem;
}
.page-layout main .content-container .content .card .notification-settings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  margin: 10px auto;
  background-color: #f9fafb;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  width: 50%;
}
.page-layout main .content-container .content .card .notification-settings label {
  font-size: 16px;
  color: #4b5563;
}
.page-layout main .content-container .content .card .notification-settings .toggle-switch {
  position: relative;
  width: 50px;
  height: 25px;
}
.page-layout main .content-container .content .card .notification-settings .toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.page-layout main .content-container .content .card .notification-settings .toggle-switch .slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d1d5db;
  border-radius: 25px;
  transition: 0.4s;
}
.page-layout main .content-container .content .card .notification-settings .toggle-switch .slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}
.page-layout main .content-container .content .card .notification-settings .toggle-switch input:checked + .slider {
  background-color: #3b82f6;
}
.page-layout main .content-container .content .card .notification-settings .toggle-switch input:checked + .slider:before {
  transform: translateX(25px);
}
.page-layout main .content-container .content .card.new-bill-card {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 20px auto;
  /*.modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 999;

      .modal-content {
          background-color: #ffffff;
          border-radius: 10px;
          padding: 30px;
          width: 90%;
          max-width: 600px;
          box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
          font-family: 'Arial', sans-serif;
          animation: slideDown 0.3s ease-out;

          @keyframes slideDown {
              from {
                  transform: translateY(-50px);
                  opacity: 0;
              }

              to {
                  transform: translateY(0);
                  opacity: 1;
              }
          }

          .modal-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 20px;
              border-bottom: 1px solid #f0f0f0;
              padding-bottom: 10px;

              h2 {
                  font-size: 24px;
                  margin: 0;
              }

              .close-btn {
                  background-color: #e74c3c;
                  color: white;
                  border: none;
                  padding: 8px 16px;
                  border-radius: 5px;
                  cursor: pointer;
                  transition: background-color 0.3s ease;

                  &:hover {
                      background-color: #c0392b;
                  }
              }
          }

          .modal-body {
              margin-top: 20px;
              line-height: 1.6;

              p {
                  margin: 8px 0;

                  strong {
                      font-weight: bold;
                  }
              }

              .services-list {
                  list-style-type: none;
                  padding: 0;
                  margin: 20px 0;

                  li {
                      background-color: #f9f9f9;
                      padding: 10px;
                      border-radius: 8px;
                      margin-bottom: 10px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      font-size: 16px;

                      &:last-child {
                          margin-bottom: 0;
                      }
                  }
              }

              .total-amount {
                  font-size: 20px;
                  font-weight: bold;
                  text-align: right;
                  margin-top: 20px;
                  color: #4caf50;
              }
          }

          .modal-footer {
              display: flex;
              justify-content: space-between;
              margin-top: 30px;

              .btn {
                  cursor: pointer;
                  padding: 10px 20px;
                  border: none;
                  border-radius: 5px;
                  font-weight: bold;
                  transition: background-color 0.3s ease;

                  &.print-btn {
                      background-color: rgba(14, 190, 243, 0.85);
                      color: white;

                      &:hover {
                          background-color: #0056b3;
                      }
                  }

                  &.close-btn {
                      background-color: #e74c3c;
                      color: white;

                      &:hover {
                          background-color: #c0392b;
                      }
                  }
              }
          }
      }
  }*/
}
.page-layout main .content-container .content .card.new-bill-card h2 {
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}
.page-layout main .content-container .content .card.new-bill-card .form-group {
  margin-bottom: 20px;
}
.page-layout main .content-container .content .card.new-bill-card .form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
  color: #666;
}
.page-layout main .content-container .content .card.new-bill-card .form-group input,
.page-layout main .content-container .content .card.new-bill-card .form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
}
.page-layout main .content-container .content .card.new-bill-card .form-group input:focus,
.page-layout main .content-container .content .card.new-bill-card .form-group select:focus {
  border-color: rgba(14, 190, 243, 0.85);
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.2);
}
.page-layout main .content-container .content .card.new-bill-card .form-group input[type=checkbox] {
  width: auto;
  margin-right: 8px;
}
.page-layout main .content-container .content .card.new-bill-card .selected-services {
  margin-top: 20px;
}
.page-layout main .content-container .content .card.new-bill-card .selected-services h3 {
  margin-bottom: 15px;
  color: #333;
  font-weight: bold;
}
.page-layout main .content-container .content .card.new-bill-card .selected-services .selected-service-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}
.page-layout main .content-container .content .card.new-bill-card .selected-services .selected-service-item button {
  background-color: #ff4d4f;
  border: none;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}
.page-layout main .content-container .content .card.new-bill-card .selected-services .selected-service-item button:hover {
  background-color: #e63946;
}
.page-layout main .content-container .content .card.new-bill-card .add-service-btn {
  background-color: #28a745;
  border: none;
  color: #fff;
  padding: 10px 15px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  display: block;
  width: 100%;
  text-align: center;
}
.page-layout main .content-container .content .card.new-bill-card .add-service-btn:hover {
  background-color: #218838;
}
.page-layout main .content-container .content .card.new-bill-card .button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.page-layout main .content-container .content .section-container {
  display: flex;
  width: 100%;
}
.page-layout main .content-container .content .section-container .box {
  width: 50%;
  padding-left: calc((100vw - 1200px) / 2);
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  margin: 0 4rem;
  gap: 1rem;
}
.page-layout main .content-container .content .section-container .box.hero-box h1,
.page-layout main .content-container .content .section-container .box.hero-box h2 {
  color: white;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 3rem;
}
.page-layout main .content-container .content .section-container .box.hero-box h1 {
  margin-top: 2rem;
}
.page-layout main .content-container .content .section-container .box.hero-box h2 {
  color: rgb(15, 185, 94);
  font-size: 2rem;
  margin-bottom: 2rem;
}
.page-layout main .content-container .content .section-container .box.hero-box p {
  color: white;
  line-height: 1.75rem;
  font-size: 1.25rem;
}
.page-layout main .content-container .content .section-container.hero {
  min-height: 500px;
  background-color: rgb(29, 58, 68);
  background-image: url("../img/laptop.png");
  background-image: url("../img/laptop_and_logo.png");
  background-repeat: no-repeat;
  background-position: right;
  background-position-x: 50vw;
  background-size: 40%;
}
.page-layout main .content-container .content .section-container.solutions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto auto;
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 2rem auto;
}
.page-layout main .content-container .content .section-container.solutions .grid-item {
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgb(29, 58, 68);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.page-layout main .content-container .content .section-container.solutions .grid-item .grid-content {
  border-radius: 4px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.9) 20%, rgba(0, 0, 0, 0) 80%);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 2rem;
  gap: 1rem;
}
.page-layout main .content-container .content .section-container.solutions .grid-item .grid-content h2 {
  color: rgb(15, 185, 94);
  font-size: 1.75rem;
  width: 50%;
  text-align: left;
  font-weight: 550;
}
.page-layout main .content-container .content .section-container.solutions .grid-item .grid-content .details {
  text-align: left;
  color: rgb(15, 185, 94);
  text-decoration: underline;
  margin-top: auto;
  margin-bottom: 0;
}
.page-layout main .content-container .content .section-container.solutions .grid-item .grid-content .description {
  text-align: left;
  color: rgb(15, 185, 94);
  color: white;
  width: 50%;
  text-shadow: 0 0 5px black;
}
.page-layout main .content-container .content .section-container.solutions .grid-item.item-1 {
  background-image: url("../img/img6.jpg");
}
.page-layout main .content-container .content .section-container.solutions .grid-item.item-1:hover {
  background-size: auto 103%;
}
.page-layout main .content-container .content .section-container.solutions .grid-item.item-2 {
  background-image: url("../img/img3.jpg");
}
.page-layout main .content-container .content .section-container.solutions .grid-item.item-2:hover {
  background-size: auto 103%;
}
.page-layout main .content-container .content .section-container.solutions .grid-item.item-3 {
  background-image: url("../img/img2.jpg");
}
.page-layout main .content-container .content .section-container.solutions .grid-item.item-3:hover {
  background-size: auto 103%;
}
.page-layout main .content-container .content .section-container.solutions .grid-item.item-4 {
  background-image: url("../img/laptop.jpg");
}
.page-layout main .content-container .content .section-container.solutions .grid-item.item-4:hover {
  background-size: auto 103%;
}
.page-layout main .content-container .content .section-container.solutions .grid-item.item-5 {
  background-image: url("../img/img5.jpg");
  background-size: cover;
  cursor: unset;
}
.page-layout main .content-container .content .section-container.solutions .grid-item.item-6 {
  background-image: url("../img/img7.jpg");
  background-size: cover;
  background-position: top;
  cursor: unset;
}
.page-layout main .content-container .content .section-container.solutions .middle-row {
  grid-column: span 3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.page-layout main .content-container .content .section-container.solutions .middle-row .item-1 {
  grid-column: 1/2;
  width: 100%;
}
.page-layout main .content-container .content .section-container.solutions .middle-row .comparison-column {
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.page-layout main .content-container .content .section-container.solutions .middle-row .comparison-column .grid-item {
  flex: 1;
}
.page-layout main .content-container .content.log-in-out-page {
  padding: 2rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.page-layout main .content-container .content.log-in-out-page .wrapper {
  display: flex;
  gap: 2rem;
}
.page-layout main .content-container .content.log-in-out-page .wrapper .panel,
.page-layout main .content-container .content.log-in-out-page .wrapper .options {
  width: 350px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  align-self: baseline;
}
.page-layout main .content-container .content.log-in-out-page .wrapper .options h2 {
  margin-bottom: 3rem;
}
.page-layout main .content-container .content.log-in-out-page .wrapper .options label {
  padding: 1rem;
  display: flex;
  gap: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
  cursor: pointer;
}
.page-layout main .content-container .content.log-in-out-page .wrapper .options label input {
  width: unset;
  width: 1.5rem;
}
.page-layout main .content-container .content.log-in-out-page form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-layout main .content-container .content.log-in-out-page h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}
.page-layout main .content-container .content.log-in-out-page .form-group {
  margin-bottom: 15px;
  width: 100%;
}
.page-layout main .content-container .content.log-in-out-page label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}
.page-layout main .content-container .content.log-in-out-page input[type=email],
.page-layout main .content-container .content.log-in-out-page input[type=tel],
.page-layout main .content-container .content.log-in-out-page input[type=password] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.page-layout main .content-container .content.log-in-out-page .form-footer {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}
.page-layout main .content-container .content.log-in-out-page .form-footer p {
  margin-top: 15px;
  font-size: 14px;
  color: #555;
}
.page-layout main .content-container .content.log-in-out-page .form-footer .if-link {
  color: #4caf50;
  text-decoration: none;
}
.page-layout main .content-container .content.log-in-out-page .form-footer .if-link:hover {
  text-decoration: underline;
}

.to-right {
  margin-left: auto;
  margin-right: 0;
}

.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}
.navigation.nav1 ul, .navigation.nav3 ul, .navigation.nav5 ul, .navigation.nav6 ul {
  display: flex;
  gap: 2rem;
  padding: 0.5rem;
  align-items: center;
  list-style: none;
  width: 100%;
  max-width: 1200px;
}
.navigation.nav1 ul li, .navigation.nav3 ul li, .navigation.nav5 ul li, .navigation.nav6 ul li {
  display: flex;
  align-items: center;
}
.navigation.nav1 ul li .menu-link, .navigation.nav3 ul li .menu-link, .navigation.nav5 ul li .menu-link, .navigation.nav6 ul li .menu-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
  transition: color 100ms;
  font-family: "Montserrat", sans-serif;
}
.navigation.nav1 ul li .menu-link:hover, .navigation.nav3 ul li .menu-link:hover, .navigation.nav5 ul li .menu-link:hover, .navigation.nav6 ul li .menu-link:hover {
  color: rgb(15, 185, 94);
}
.navigation.nav1 ul li .menu-link .logo, .navigation.nav3 ul li .menu-link .logo, .navigation.nav5 ul li .menu-link .logo, .navigation.nav6 ul li .menu-link .logo {
  height: 2rem;
}
.navigation.nav1 ul li .icon, .navigation.nav3 ul li .icon, .navigation.nav5 ul li .icon, .navigation.nav6 ul li .icon {
  margin-right: 0.5rem;
}
.navigation.nav1 ul li .icon.language-icon, .navigation.nav3 ul li .icon.language-icon, .navigation.nav5 ul li .icon.language-icon, .navigation.nav6 ul li .icon.language-icon {
  font-size: 1.25rem;
  margin-right: 0;
}
.navigation.nav1 ul li.language-item, .navigation.nav3 ul li.language-item, .navigation.nav5 ul li.language-item, .navigation.nav6 ul li.language-item {
  justify-content: space-between;
  gap: 1rem;
}
.navigation.nav1 ul li.language-item select, .navigation.nav3 ul li.language-item select, .navigation.nav5 ul li.language-item select, .navigation.nav6 ul li.language-item select {
  width: unset;
  padding: 0.25rem;
}
.navigation.nav2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -25px;
}
.navigation.nav2 ul {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  height: 100%;
  list-style: none;
  border-radius: 0.25rem;
  background-color: white;
  box-shadow: 0 0 10px 3px rgba(13, 51, 63, 0.2);
}
.navigation.nav2 ul li a {
  text-decoration: none;
  color: #007bff;
  font-weight: 500;
  transition: color 100ms;
}
.navigation.nav2 ul li a:hover {
  color: #0056b3;
}
.navigation.nav7 {
  border-bottom: 2px solid rgba(221, 221, 221, 0.2);
}
.navigation.nav7 ul {
  display: flex;
  width: 100%;
}
.navigation.nav7 ul li {
  display: flex;
  align-items: center;
  list-style: none;
}
.navigation.nav7 ul li.signed-in-name a {
  font-weight: 500;
  font-size: 1.25rem;
}
.navigation.nav7 ul li button {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  padding: 0.75rem;
  height: 3.5rem;
  min-width: 3.5rem;
  font-size: 1rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.navigation.nav7 ul li button .icon {
  font-size: 1.25rem;
}
.navigation.nav7 ul li button .icon.collapse-icon {
  transform: rotate(180deg);
  font-size: 1.5rem;
}
.navigation.nav7 ul li button .icon.hamburger-icon {
  transition: all 350ms ease-in-out;
}
.navigation.nav7 ul li button .icon.home-icon {
  font-size: 1rem;
}
.navigation.nav7 ul li button:hover {
  background-color: rgb(238, 238, 238);
}
.navigation.nav7 ul li .profile-btn span {
  background-color: rgb(243, 122, 14);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1rem;
}
.navigation.nav8 ul {
  display: flex;
}
.navigation.side-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  min-width: 164px;
  background-color: #1f2937;
  transition: all 350ms ease-in-out;
  border-radius: 0 10px 10px 0;
}
.navigation.side-nav .nav-head {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}
.navigation.side-nav .nav-head * {
  color: white !important;
}
.navigation.side-nav .nav-head .brand {
  font-size: 1.5rem;
  white-space: nowrap;
}
.navigation.side-nav .nav-head .brand a {
  display: flex;
  align-items: center;
}
.navigation.side-nav .nav-head .brand a .logo {
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
  transition: all 350ms ease-in-out;
  border-radius: 50%;
}
.navigation.side-nav .nav-head .brand a .logo:hover {
  transform: scale(1.3);
  background-color: white;
  padding: 2px;
}
.navigation.side-nav .nav-head .brand a:hover {
  text-decoration: underline;
}
.navigation.side-nav .nav-link {
  padding: 0.5rem;
  cursor: pointer;
  color: rgb(243, 122, 14);
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  align-items: center;
}
.navigation.side-nav .nav-link:hover {
  background-color: rgb(75, 98, 131);
}
.navigation.side-nav .nav-link .icon {
  font-size: 1.25rem;
  transition: all 300ms ease-in-out;
  height: 2rem;
  width: 2rem;
  padding: 0.4rem;
  cursor: pointer;
}
.navigation.side-nav .nav-link .icon * {
  color: rgb(240, 240, 240);
}
.navigation.side-nav .nav-link.active-link {
  color: rgb(240, 240, 240);
}

.banner,
.copyright {
  background-color: rgb(29, 58, 68);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  color: white;
  gap: 1rem;
}
.banner *,
.copyright * {
  color: white;
}

.copyright {
  justify-content: flex-start;
  padding-left: calc((100% - 1200px) / 2);
}
.copyright p {
  padding-left: 0.5rem;
  font-size: 0.85rem;
}

.overview {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  gap: 2rem;
}
.overview .box-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 700px;
}
.overview .box-container .box {
  padding: 2rem;
  border-radius: 0.25rem;
  border: 2px solid rgba(0, 0, 0, 0.075);
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
}
.overview .box-container .box .box-title {
  font-weight: 500;
  margin-bottom: 2rem;
}
.overview .box-container .box .box-grid {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}
.overview .box-container .box .box-grid .box-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 0.5rem;
  text-align: center;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 120px;
  height: 120px;
}
.overview .box-container .box .box-grid .box-item * {
  transition: all 150ms ease-in-out;
  color: #3b82f6;
  color: #55709c;
}
.overview .box-container .box .box-grid .box-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
.overview .box-container .box .box-grid .box-item:hover .icon-container *,
.overview .box-container .box .box-grid .box-item:hover .link-text {
  color: rgb(243, 122, 14);
}
.overview .box-container .box .box-grid .box-item .icon-container {
  margin-bottom: 0.75rem;
  font-size: 2rem;
}
.overview .box-container .box .box-grid .box-item .link-text {
  font-weight: 500;
  text-transform: capitalize;
}
.overview .box-container .box.settings-box .box-grid {
  flex-direction: column;
  align-items: center;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.modal .wrapper {
  display: flex;
  flex-direction: column;
  width: 800px;
  height: 100%;
  position: relative;
  transition: margin 0.3s ease-in-out;
}
.modal .wrapper .modal-header,
.modal .wrapper .modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: white;
}
.modal .wrapper .modal-header .icon.close-icon,
.modal .wrapper .modal-footer .icon.close-icon {
  font-size: 1.5rem;
  cursor: pointer;
}
.modal .wrapper .modal-header .icon.close-icon:hover,
.modal .wrapper .modal-footer .icon.close-icon:hover {
  background-color: rgb(238, 238, 238);
}
.modal .wrapper .modal-header {
  border-bottom: 2px solid rgba(221, 221, 221, 0.2);
}
.modal .wrapper .modal-footer {
  justify-content: flex-end;
  border-top: 2px solid rgba(221, 221, 221, 0.2);
  margin-top: auto;
  margin-bottom: 0;
}
.modal .wrapper .modal-body {
  overflow-y: scroll;
  height: 100%;
}
.modal .wrapper .modal-body .modal-body-content {
  background-color: white;
  padding: 2rem;
  min-height: 100%;
}/*# sourceMappingURL=App.css.map */