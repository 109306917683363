@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

$max-width: 1200px;

// Colours
// $green: rgb(15, 185, 94);
// $green: rgb(194, 20, 81);
// $green: rgb(185, 15, 15);
$green: rgb(15, 185, 94);
//$primary-bg-color: rgb(12, 73, 107);
$primary-bg-color: rgb(29, 58, 68);
$secondary-bg-color: #1f2937;


// Reset
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: rgb(66, 66, 66);
    text-decoration: none;
    font-weight: normal;

    nav {
        user-select: none
    }

    .visible-item {
        opacity: 100%;
        transition: all 250ms ease-in-out;
    }

    .hidden-item {
        opacity: 0%;
        pointer-events: none;
        transition: all 250ms ease-in-out;
    }
}

// Button styles
.btn {
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-size: 1rem;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    align-self: center;
    gap: 0.5rem;
    cursor: pointer;
    user-select: none;
    min-height: 34px;

    justify-self: flex-start;

    &:active {
        transform: translateY(2px);
    }

    * {
        color: white;
    }

    .icon {
        font-size: 1rem;
        height: 1rem;
    }

    .mirrorY {
        transform: rotateY(180deg);
    }

    &.action-btn {
        background-color: rgba(30, 74, 140, 0.85);

        &:hover {
            background-color: rgba(30, 74, 140, 1);
        }
    }

    &.mini-btn {
        background-color: transparent;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        width: 2rem;
        border-radius: 0;

        * {
            color: green;
        }

        &:last-child {
            margin-left: 0.5rem;

            * {
                color: red;
            }
        }

        &:hover {
            background-color: rgb(214, 214, 214);
        }
    }

    &.auth-btn {
        background-color: rgba(14, 190, 243, 0.85);
        background-color: rgba(12, 73, 107, 0.85);

        &:hover {
            background-color: rgba(12, 73, 107, 1);
        }
    }

    &.card-head-btn {
        background-color: rgba(14, 190, 243, 0.85);
        background-color: rgba(12, 73, 107, 0.85);
        border-radius: 0;

        &:hover {
            background-color: rgba(12, 73, 107, 1);
        }
    }

    &.framed-btn {
        background-color: white;
        color: unset;
        border: 2px solid rgba(66, 66, 66, 0.3);
        transition: all 350ms ease-in-out;

        .icon {
            height: 1.25rem;
            width: 1.25rem;

            * {
                color: black;
            }
        }

        &:hover {
            border-color: black;
        }
    }

    &.bold-btn {
        background-color: white;
        color: unset;
        transition: all 350ms ease-in-out;
        padding: 0.5rem;
        transition: none;

        .icon {
            height: 1.25rem;
            width: 1.25rem;

            * {
                color: black;
            }
        }

        &:hover {
            background-color: rgb(238, 238, 238);
        }
    }

    &.active-framed-btn {
        background-color: black;
        color: white;
        border: 2px solid black;
    }

    &.marketing-btn {
        align-self: flex-start;
        background-color: rgb(15, 185, 94);
        margin-top: 2rem;

        &:hover {
            background-color: rgba(15, 185, 94, 0.85);
        }
    }
}

// Breadcrumbs styles
.breadcrumbs {
    margin-bottom: 2rem;
    font-size: 0.8rem;
    opacity: 50%;
    padding: 1rem;
}

// Input styles
input {
    &[type="checkbox"] {
        width: 1.5rem;
        height: 1.5rem;
    }
}

input,
select,
textarea {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 30%;
    font-size: 1rem;

    &:focus {
        border-color: rgb(0, 123, 255);
        outline: none;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
    }
}

textarea {
    resize: vertical;
}

.icon-set {
    display: flex;
    gap: 1px;
    list-style: none;
}


// Admin styles
.admin-code {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 0.75rem;

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid gray;
        width: 2.5rem;
        height: 2.5rem;
        font-weight: bold;
        font-size: 1.5rem;
        border-radius: 5px;
        text-transform: capitalize;
    }

    .icon {
        cursor: pointer;
        font-size: 1.25rem;
    }
}

.button-container {
    display: flex;
    justify-content: center;
    padding: 2rem;
    gap: 1rem;
}

.table-container {
    h3 {
        text-align: center;
        margin-bottom: 1.5rem;
        color: #333;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 1rem;

        thead {
            tr {
                background-color: rgba(38, 86, 100, 0.75);

                th {
                    padding: 0.75rem;
                    text-align: left;
                    color: white;

                    span {
                        display: flex;
                        align-items: center;
                        color: white;

                        * {
                            color: white;
                        }

                        .icon {
                            cursor: pointer;
                            margin-left: 0.25rem;
                        }

                        input {
                            margin-right: 1rem;
                            width: 1rem;
                            cursor: pointer;
                        }
                    }

                    &:last-child {
                        width: 6.5rem;
                    }
                }
            }
        }

        tbody {
            tr {
                cursor: pointer;

                &:hover {
                    background-color: #f2f2f2;
                }

                td {
                    padding: 0.75rem;
                    border-bottom: 1px solid #ddd;

                    &:last-child {
                        width: 6.5rem;
                        text-align: right;
                    }

                    span {
                        display: flex;
                        align-items: center;

                        input {
                            margin-right: 1rem;
                            width: 1rem;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    .action-cell {
        text-align: unset;
    }

    p {
        text-align: center;
        color: #888;
        font-style: italic;
    }
}

// App styles
.App {
    min-height: 100vh;
    display: flex;
}

// General page layout styles
.page-layout {
    display: flex;
    flex-direction: column;
    width: 100%;

    header,
    footer {
        display: flex;
        flex-direction: column;
    }

    header {
        border-bottom: 2px solid rgba(13, 51, 63, 0.1);

    }

    footer {
        margin-top: auto;
        margin-bottom: 0;
        border-top: 2px solid rgba(13, 51, 63, 0.1);
    }

    main {
        display: flex;
        height: 100%;

        aside {
            background-color: $secondary-bg-color;
            border-radius: 0 10px 10px 0;
        }

        .content-container {
            width: 100%;

            .content {
                width: 100%;
                display: flex;
                flex-direction: column;

                .card {
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                    padding: 2rem;
                    margin-bottom: 2rem;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

                    h2 {
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                    }

                    .card-header {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 2rem;
                        gap: 2rem;

                        .table-filter {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;

                            .icon {

                                &.search-icon {
                                    height: 30px;
                                    width: 30px;
                                    margin-left: -33px;
                                    background-color: white;
                                    cursor: text;
                                }

                                &.import-icon {
                                    transform: rotateY(180deg);
                                }

                            }
                        }
                    }

                    form {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 1rem;

                        .form-group {
                            display: flex;
                            align-items: center;
                            width: 100%;

                            label {
                                text-align: right;
                                padding-right: 2rem;
                                width: 35%;
                            }

                            .name-span {
                                text-transform: capitalize;
                            }
                        }

                        .btn {
                            margin-top: 1rem;
                        }
                    }

                    .notification-settings {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 15px 20px;
                        margin: 10px auto;
                        background-color: #f9fafb;
                        border-radius: 8px;
                        border: 1px solid #d1d5db;
                        width: 50%;

                        label {
                            font-size: 16px;
                            color: #4b5563;
                        }

                        .toggle-switch {
                            position: relative;
                            width: 50px;
                            height: 25px;

                            input {
                                opacity: 0;
                                width: 0;
                                height: 0;
                            }

                            .slider {
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background-color: #d1d5db;
                                border-radius: 25px;
                                transition: 0.4s;

                                &:before {
                                    position: absolute;
                                    content: "";
                                    height: 19px;
                                    width: 19px;
                                    left: 3px;
                                    bottom: 3px;
                                    background-color: white;
                                    border-radius: 50%;
                                    transition: 0.4s;
                                }
                            }

                            input:checked+.slider {
                                background-color: #3b82f6;
                            }

                            input:checked+.slider:before {
                                transform: translateX(25px);
                            }
                        }
                    }

                    &.new-bill-card {
                        padding: 20px;
                        background-color: #f9f9f9;
                        border-radius: 8px;
                        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                        max-width: 600px;
                        margin: 20px auto;

                        h2 {
                            margin-bottom: 20px;
                            text-align: center;
                            color: #333;
                        }

                        .form-group {
                            margin-bottom: 20px;

                            label {
                                display: block;
                                font-weight: bold;
                                margin-bottom: 8px;
                                color: #666;
                            }

                            input,
                            select {
                                width: 100%;
                                padding: 10px;
                                border: 1px solid #ddd;
                                border-radius: 4px;
                                font-size: 16px;
                                outline: none;

                                &:focus {
                                    border-color: rgba(14, 190, 243, 0.85);
                                    box-shadow: 0 0 4px rgba(0, 123, 255, 0.2);
                                }
                            }

                            input[type='checkbox'] {
                                width: auto;
                                margin-right: 8px;
                            }
                        }

                        .selected-services {
                            margin-top: 20px;

                            h3 {
                                margin-bottom: 15px;
                                color: #333;
                                font-weight: bold;
                            }

                            .selected-service-item {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                background-color: #f1f1f1;
                                padding: 10px;
                                border-radius: 4px;
                                margin-bottom: 10px;

                                button {
                                    background-color: #ff4d4f;
                                    border: none;
                                    color: #fff;
                                    padding: 5px 10px;
                                    cursor: pointer;
                                    border-radius: 4px;
                                    font-size: 14px;

                                    &:hover {
                                        background-color: #e63946;
                                    }
                                }
                            }
                        }

                        .add-service-btn {
                            background-color: #28a745;
                            border: none;
                            color: #fff;
                            padding: 10px 15px;
                            margin-top: 10px;
                            cursor: pointer;
                            border-radius: 4px;
                            font-size: 16px;
                            display: block;
                            width: 100%;
                            text-align: center;

                            &:hover {
                                background-color: #218838;
                            }
                        }

                        .button-container {
                            display: flex;
                            justify-content: space-between;
                            margin-top: 20px;

                        }

                        /*.modal {
                            position: fixed;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0, 0, 0, 0.6);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            z-index: 999;

                            .modal-content {
                                background-color: #ffffff;
                                border-radius: 10px;
                                padding: 30px;
                                width: 90%;
                                max-width: 600px;
                                box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
                                font-family: 'Arial', sans-serif;
                                animation: slideDown 0.3s ease-out;

                                @keyframes slideDown {
                                    from {
                                        transform: translateY(-50px);
                                        opacity: 0;
                                    }

                                    to {
                                        transform: translateY(0);
                                        opacity: 1;
                                    }
                                }

                                .modal-header {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    margin-bottom: 20px;
                                    border-bottom: 1px solid #f0f0f0;
                                    padding-bottom: 10px;

                                    h2 {
                                        font-size: 24px;
                                        margin: 0;
                                    }

                                    .close-btn {
                                        background-color: #e74c3c;
                                        color: white;
                                        border: none;
                                        padding: 8px 16px;
                                        border-radius: 5px;
                                        cursor: pointer;
                                        transition: background-color 0.3s ease;

                                        &:hover {
                                            background-color: #c0392b;
                                        }
                                    }
                                }

                                .modal-body {
                                    margin-top: 20px;
                                    line-height: 1.6;

                                    p {
                                        margin: 8px 0;

                                        strong {
                                            font-weight: bold;
                                        }
                                    }

                                    .services-list {
                                        list-style-type: none;
                                        padding: 0;
                                        margin: 20px 0;

                                        li {
                                            background-color: #f9f9f9;
                                            padding: 10px;
                                            border-radius: 8px;
                                            margin-bottom: 10px;
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                            font-size: 16px;

                                            &:last-child {
                                                margin-bottom: 0;
                                            }
                                        }
                                    }

                                    .total-amount {
                                        font-size: 20px;
                                        font-weight: bold;
                                        text-align: right;
                                        margin-top: 20px;
                                        color: #4caf50;
                                    }
                                }

                                .modal-footer {
                                    display: flex;
                                    justify-content: space-between;
                                    margin-top: 30px;

                                    .btn {
                                        cursor: pointer;
                                        padding: 10px 20px;
                                        border: none;
                                        border-radius: 5px;
                                        font-weight: bold;
                                        transition: background-color 0.3s ease;

                                        &.print-btn {
                                            background-color: rgba(14, 190, 243, 0.85);
                                            color: white;

                                            &:hover {
                                                background-color: #0056b3;
                                            }
                                        }

                                        &.close-btn {
                                            background-color: #e74c3c;
                                            color: white;

                                            &:hover {
                                                background-color: #c0392b;
                                            }
                                        }
                                    }
                                }
                            }
                        }*/
                    }
                }

                .section-container {
                    display: flex;
                    width: 100%;

                    .box {
                        width: 50%;
                        padding-left: calc((100vw - $max-width) / 2);
                        padding-top: 4rem;
                        padding-bottom: 4rem;
                        display: flex;
                        flex-direction: column;
                        margin: 0 4rem;
                        gap: 1rem;

                        &.hero-box {

                            h1,
                            h2 {
                                color: white;
                                font-weight: 600;
                                letter-spacing: 1px;
                                font-size: 3rem;
                            }

                            h1 {
                                margin-top: 2rem;
                            }

                            h2 {
                                color: $green;
                                font-size: 2rem;
                                margin-bottom: 2rem;
                            }

                            p {
                                color: white;
                                line-height: 1.75rem;
                                font-size: 1.25rem;
                            }
                        }
                    }

                    &.hero {
                        min-height: 500px;
                        background-color: $primary-bg-color;
                        background-image: url('../img/laptop.png');
                        background-image: url('../img/laptop_and_logo.png');
                        background-repeat: no-repeat;
                        background-position: right;
                        background-position-x: 50vw;
                        background-size: 40%;
                    }

                    &.solutions {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        grid-template-rows: auto auto auto;
                        gap: 20px;
                        padding: 20px;
                        max-width: $max-width;
                        margin: 2rem auto;

                        .grid-item {
                            border: 1px solid #ddd;
                            border-radius: 4px;
                            font-size: 1rem;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            text-align: center;

                            background-color: $primary-bg-color;

                            background-repeat: no-repeat;
                            background-size: auto 100%;
                            background-position: center;

                            transition: all 0.3s ease-in-out;

                            cursor: pointer;

                            .grid-content {
                                border-radius: 4px;
                                background: linear-gradient(to right, rgba(0, 0, 0, 0.9) 20%, rgba(0, 0, 0, 0) 80%);
                                display: flex;
                                flex-direction: column;
                                //justify-content: space-between;
                                height: 100%;
                                width: 100%;
                                padding: 2rem;
                                gap: 1rem;

                                h2 {
                                    color: $green;
                                    font-size: 1.75rem;
                                    width: 50%;
                                    text-align: left;
                                    font-weight: 550;
                                }

                                .details {
                                    text-align: left;
                                    color: $green;
                                    text-decoration: underline;
                                    margin-top: auto;
                                    margin-bottom: 0;
                                }

                                .description {
                                    text-align: left;
                                    color: $green;
                                    color: white;
                                    width: 50%;
                                    text-shadow: 0 0 5px black;
                                }
                            }

                            &.item-1 {
                                background-image: url('../img/img6.jpg');

                                &:hover {
                                    background-size: auto 103%;
                                }
                            }

                            &.item-2 {
                                background-image: url('../img/img3.jpg');

                                &:hover {
                                    background-size: auto 103%;
                                }
                            }

                            &.item-3 {
                                background-image: url('../img/img2.jpg');

                                &:hover {
                                    background-size: auto 103%;
                                }
                            }

                            &.item-4 {
                                //min-height: 400px;
                                background-image: url("../img/laptop.jpg");

                                &:hover {
                                    background-size: auto 103%;
                                }
                            }

                            &.item-5 {
                                background-image: url('../img/img5.jpg');
                                background-size: cover;
                                cursor: unset;
                            }

                            &.item-6 {
                                background-image: url('../img/img7.jpg');
                                background-size: cover;
                                background-position: top;
                                cursor: unset;
                            }

                        }

                        .middle-row {
                            grid-column: span 3;
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            gap: 20px;

                            .item-1 {
                                grid-column: 1 / 2;
                                width: 100%;
                            }

                            .comparison-column {
                                grid-column: 2 / 3;
                                display: flex;
                                flex-direction: column;
                                gap: 20px;

                                .grid-item {
                                    flex: 1;
                                }
                            }
                        }
                    }
                }

                &.log-in-out-page {
                    padding: 2rem;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 2rem;

                    .wrapper {
                        display: flex;
                        gap: 2rem;

                        .panel,
                        .options {
                            width: 350px;
                            background-color: rgba(255, 255, 255, 0.8);
                            padding: 1.5rem;
                            border-radius: 10px;
                            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                            align-self: baseline;
                            //margin-top: auto;
                            //margin-bottom: auto;
                        }

                        .options {
                            h2 {
                                margin-bottom: 3rem;
                            }

                            label {
                                padding: 1rem;
                                display: flex;
                                gap: 1rem;
                                border: 1px solid #ccc;
                                border-radius: 4px;
                                margin-bottom: 1rem;
                                cursor: pointer;

                                input {
                                    width: unset;
                                    width: 1.5rem;
                                }
                            }
                        }
                    }

                    form {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                    }

                    h2 {
                        text-align: center;
                        color: #333;
                        margin-bottom: 20px;
                    }

                    .form-group {
                        margin-bottom: 15px;
                        width: 100%;
                    }

                    label {
                        display: block;
                        margin-bottom: 5px;
                        color: #555;
                    }

                    input[type="email"],
                    input[type="tel"],
                    input[type="password"] {
                        width: 100%;
                        padding: 10px;
                        font-size: 16px;
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        box-sizing: border-box;
                    }

                    .form-footer {
                        display: flex;
                        text-align: center;
                        flex-direction: column;
                        align-items: center;



                        p {
                            margin-top: 15px;
                            font-size: 14px;
                            color: #555;
                        }

                        .if-link {
                            color: #4caf50;
                            text-decoration: none;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Navigation styles
.to-right {
    margin-left: auto;
    margin-right: 0;
}

.navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);


    &.nav1,
    &.nav3,
    &.nav5,
    &.nav6 {
        ul {
            display: flex;
            gap: 2rem;
            padding: 0.5rem;
            align-items: center;
            list-style: none;
            width: 100%;
            max-width: $max-width;

            li {
                display: flex;
                align-items: center;

                .menu-link {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    font-weight: 600;
                    transition: color 100ms;
                    font-family: "Montserrat", sans-serif;

                    &:hover {
                        color: $green;
                    }

                    .logo {
                        height: 2rem;
                    }
                }

                .icon {
                    margin-right: 0.5rem;

                    &.language-icon {
                        font-size: 1.25rem;
                        margin-right: 0;
                    }
                }

                &.language-item {
                    justify-content: space-between;

                    gap: 1rem;

                    select {
                        width: unset;
                        padding: 0.25rem;
                    }
                }
            }
        }
    }

    &.nav2 {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -25px;

        ul {
            display: flex;
            gap: 0.5rem;
            padding: 0.5rem;
            justify-content: center;
            align-items: center;
            height: 100%;
            list-style: none;
            border-radius: 0.25rem;
            background-color: white;
            box-shadow: 0 0 10px 3px rgba(13, 51, 63, 0.2);

            li {
                a {
                    text-decoration: none;
                    color: #007bff;
                    font-weight: 500;
                    transition: color 100ms;

                    &:hover {
                        color: #0056b3;
                    }
                }
            }
        }
    }

    &.nav7 {
        border-bottom: 2px solid rgba(221, 221, 221, 0.2);

        ul {
            display: flex;
            width: 100%;
            //align-items: center;

            li {
                display: flex;
                align-items: center;
                list-style: none;

                &.signed-in-name {
                    a {
                        font-weight: 500;
                        font-size: 1.25rem;
                    }
                }

                button {
                    display: flex;
                    justify-content: center;
                    gap: 0.5rem;
                    align-items: center;
                    padding: 0.75rem;
                    height: 3.5rem;
                    min-width: 3.5rem;
                    font-size: 1rem;
                    border: none;
                    background-color: transparent;
                    cursor: pointer;

                    .icon {
                        font-size: 1.25rem;

                        &.collapse-icon {
                            transform: rotate(180deg);
                            font-size: 1.5rem;
                        }

                        &.hamburger-icon {
                            transition: all 350ms ease-in-out;
                        }

                        &.home-icon {
                            font-size: 1rem;
                        }
                    }

                    &:hover {
                        background-color: rgb(238, 238, 238);
                    }
                }

                .profile-btn {
                    span {
                        background-color: rgb(243, 122, 14);
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: white;
                        font-size: 1rem;
                    }
                }
            }
        }
    }

    &.nav8 {
        ul {
            display: flex;
        }
    }

    &.side-nav {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem;
        min-width: 164px;
        background-color: $secondary-bg-color;
        transition: all 350ms ease-in-out;
        border-radius: 0 10px 10px 0;

        .nav-head {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 1rem;

            * {
                color: white !important;
            }

            .brand {
                font-size: 1.5rem;
                white-space: nowrap;

                a {
                    display: flex;
                    align-items: center;

                    .logo {
                        height: 2rem;
                        width: 2rem;
                        margin-right: 1rem;
                        transition: all 350ms ease-in-out;
                        border-radius: 50%;

                        &:hover {
                            transform: scale(1.3);
                            background-color: white;
                            padding: 2px;
                        }
                    }


                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .nav-link {
            padding: 0.5rem;
            cursor: pointer;
            color: rgb(243, 122, 14);
            display: flex;
            justify-content: space-between;
            width: 100%;
            gap: 1rem;
            align-items: center;

            &:hover {
                background-color: rgb(75, 98, 131);
            }

            .icon {
                font-size: 1.25rem;
                transition: all 300ms ease-in-out;
                height: 2rem;
                width: 2rem;
                padding: 0.4rem;
                cursor: pointer;

                * {
                    color: rgb(240, 240, 240);
                }
            }

            &.active-link {
                color: rgb(240, 240, 240);
            }
        }
    }
}

// Banner and Copyright styles
.banner,
.copyright {
    background-color: $primary-bg-color;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;
    color: white;
    gap: 1rem;

    * {
        color: white;
    }
}

.copyright {
    justify-content: flex-start;
    padding-left: calc((100% - $max-width)/2);

    p {
        padding-left: 0.5rem;
        font-size: 0.85rem;
    }
}

.overview {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 2rem;
    gap: 2rem;

    .box-container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        max-width: 700px;

        .box {
            padding: 2rem;
            //background-color: #f9fafb;
            border-radius: 0.25rem;
            //box-shadow: 0 5px 4px rgba(0, 0, 0, 0.1);
            border: 2px solid rgba(0, 0, 0, 0.075);
            display: flex;
            flex-direction: column;
            align-self: flex-start;
            width: 100%;

            .box-title {
                font-weight: 500;
                margin-bottom: 2rem;
            }

            .box-grid {
                display: flex;
                gap: 2rem;
                flex-wrap: wrap;

                .box-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: #fff;
                    border-radius: 0.5rem;
                    text-align: center;
                    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
                    transition: transform 0.3s ease, box-shadow 0.3s ease;
                    width: 120px;
                    height: 120px;

                    * {
                        transition: all 150ms ease-in-out;
                        color: #3b82f6;
                        color: #55709c;
                    }

                    &:hover {
                        transform: translateY(-5px);
                        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);

                        .icon-container *,
                        .link-text {
                            color: rgb(243, 122, 14);
                        }
                    }

                    .icon-container {
                        margin-bottom: 0.75rem;
                        font-size: 2rem;
                    }

                    .link-text {
                        font-weight: 500;
                        text-transform: capitalize;
                    }
                }
            }

            &.settings-box {
                .box-grid {
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
    }
}

.modal {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    .wrapper {
        display: flex;
        flex-direction: column;
        width: 800px;
        height: 100%;
        position: relative;
        transition: margin 0.3s ease-in-out;

        .modal-header,
        .modal-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1rem 2rem;
            background-color: white;

            .icon {

                &.close-icon {
                    font-size: 1.5rem;
                    cursor: pointer;

                    &:hover {
                        background-color: rgb(238, 238, 238);
                    }
                }
            }
        }

        .modal-header {
            border-bottom: 2px solid rgba(221, 221, 221, 0.2);
        }

        .modal-footer {
            justify-content: flex-end;
            border-top: 2px solid rgba(221, 221, 221, 0.2);
            margin-top: auto;
            margin-bottom: 0;
        }

        .modal-body {
            overflow-y: scroll;
            height: 100%;

            .modal-body-content {
                background-color: white;
                padding: 2rem;
                min-height: 100%;
            }
        }
    }
}